<template>
  <div
    :id="modalId"
    :class="classes"
    :style="this.styles"
    tabindex="-1"
    role="dialog"
  >
    <div class="modal-dialog modal-lg" v-if="data">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title">{{data.name}}</h5>
          <button type="button" class="close" @click="hide" aria-label="Close">
            <svg aria-hidden="true" style="width: 15px;" focusable="false" data-prefix="fas" data-icon="xmark" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 320 512" class="svg-inline--fa fa-xmark fa-xl"><path fill="currentColor" d="M310.6 150.6c12.5-12.5 12.5-32.8 0-45.3s-32.8-12.5-45.3 0L160 210.7 54.6 105.4c-12.5-12.5-32.8-12.5-45.3 0s-12.5 32.8 0 45.3L114.7 256 9.4 361.4c-12.5 12.5-12.5 32.8 0 45.3s32.8 12.5 45.3 0L160 301.3 265.4 406.6c12.5 12.5 32.8 12.5 45.3 0s12.5-32.8 0-45.3L205.3 256 310.6 150.6z" class=""></path></svg>
          </button>
        </div>
        <div class="modal-body relative">
          <div class="form-group">
            <div class="row" >
              <div class="col-md-12">
                <div class="slide-tab-header">
                  <slide-tab firstName="Relatório" lastName="Lançamentos" tabPositionMax="50" tabCustom="tab-custom" @changeTabCallBack="changeTab"/>
                </div>
                
              </div>
            </div>
            <div class="row">
              <div class="col-sm-3 col-lg-3 col-md-3 text-right" style="padding: 15px; padding-left: 20px;">
                <input type="checkbox" id="for_is_sub" v-model="subcategories" @change="checkBudget">
                <label for="for_is_sub" class="to-left">Subcategorias</label>
              </div>
              <div class="col-sm-6 col-lg-6 col-md-6 text-right" style="padding: 15px; padding-left: 20px;">
                <input type="checkbox" id="for_is_card_cat" v-model="no_card" @change="checkBudget">
                <label for="for_is_card_cat" class="to-left">Despesas de catão</label>
              </div>
              <div class="col-md-12">
                <div class="card-body no-padding relative">
                  <div style="font-size: 26px;">
                    <div class="block" style="font-size: 18px; margin-bottom: 10px; margin-top: 10px;">
                      Despesas de cartão
                    </div>
                    <div class="block" style="font-weight: 200;margin-bottom: 20px;">{{totalCard | currency}}</div>
                  </div>
                  <div class="chart-legend-item" style="font-size: 20px;">
                    <div class="icon-category-overview " style="width: 50px; height: 50px; padding-top: 8px;" :style="`background: ${data.color}`">
                      <img :src="`assets/images/icons/png/${data.icon}.png`" />
                    </div>
                    {{data.name}}
                    <strong style="position: absolute; right: 20px"
                      >{{(total + totalCard) | currency}}</strong
                    >
                  </div>
                </div>
              </div>
            </div>
            <div class="row" v-if="miniTab.tab === 0">
              <div class="col-sm-12">
                <v-chart class="chart-line" :option="optionChart" v-if="this.data.type == 'Expense'" />
                <v-chart class="chart-line" :option="optionChartIncome" v-if="this.data.type == 'Income'"/>
              </div>
            </div>
            <div class="row" v-if="miniTab.tab === 1">
                <div class="col-md-12">
                  <h3 class="display-4">Transações</h3>
                  <hr>
                  <div class="row ng-scope" v-if="report.transactions.transactions.length == 0">
                    <img :src="`assets/images/report.svg`" class="nothing-image w-15p" style="width: 10% !important">
                    <h3 class="nothing-text">Nenhuma transação encontrada</h3>
                  </div>
                  <ul class="list-group" v-if="report.transactions.transactions">
                    <li class="list-group-item ng-scope" v-for="(item, i) in report.transactions.transactions" :key="i">
                      <div class="row">
                        <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-6">
                          <span class="text-danger" v-if="item.transaction_type == 'expense'"><span class="ng-binding">- {{item.amount | currency }}</span></span>
                          <span class="text-success" v-if="item.transaction_type == 'income'"><span class="ng-binding">+ {{item.amount | currency }}</span></span>
                        </div>
                        <div class="col-sm-1 col-lg-1 col-xs-6 text-right">
                          <span class="label label-danger" v-if="item.transaction_type == 'expense'">Despesa</span>
                          <span class="label label-success" v-if="item.transaction_type == 'income'">Receita</span>
                        </div>
                        <div class="col-sm-2 col-sm-2 col-lg-2 col-xs-12">
                          <strong class="ng-binding" v-if="item.account">{{item.account.name }}</strong>
                          <strong class="ng-binding" v-if="item.card">{{item.card.name }}</strong>
                        </div>
                        <div class="col-sm-3 col-lg-3 col-xs-12 m-p-top">
                          <span class="chart-legend-item relative" style="border:none; margin:0px; padding:0px;">
                            <div v-tooltip.top="`${item.category.name}`" class="icon-category-overview" :style="`margin-right:0px;background: ${item.category.color}`">
                              <img :src="`/assets/images/icons/png/${item.category.icon}.png`" />
                            </div>
                            &nbsp;<span class="hidden-sm visible-only-xs">{{item.category.name}}</span>
                          </span>
                        </div>
                        <div class="col-sm-4 col-sm-4 col-lg-4 col-xs-12 text-right">
                          <span class="ng-binding">{{ item.transaction_date | moment("DD/MM/YYYY") }}&nbsp;&nbsp;</span>
                          <i class="fa fa-credit-card" v-if="item.card"></i>
                        </div>
                        <div class="col-sm-12" v-if="item.description" style="clear: both; padding-top: 0px;">
                          <hr class="hr-transactions">
                          <span class="ng-binding transaction-note">{{item.description }}</span>
                        </div>
                      </div>
                    </li>
                  </ul>
                  <hr>
                  <h5 style="float: right;" class="font-w-100 reports-period">Período: {{ this.data.from | moment("DD/MM/YYYY") }} até {{ this.data.to | moment("DD/MM/YYYY") }}</h5>
                </div>
            </div>
            <div class="row" >
              <div class="col-md-12 text-right">

                <hr>
                <button @click="createTransaction()" type="button" v-tooltip.top="'Nova transação'" class="btn btn-green color-white btn-green-shadow btn-add-transaction">
                  <i class="fa fa-plus"></i>
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { use } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { PieChart } from "echarts/charts";
import { LineChart } from "echarts/charts";
import Modal from '@/mixins/Modal';
import AppService from '@/services/app.service';
import 'vue2-datepicker/index.css';
import 'vue2-datepicker/locale/zh-cn';
import SlideTab from '@/components/SlideTab';
import {
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
} from "echarts/components";
import VChart, { THEME_KEY } from "vue-echarts";
import { graphic } from 'echarts';

use([
  CanvasRenderer,
  PieChart,
  TitleComponent,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  ToolboxComponent,
  LineChart,
]);

export default {
  mixins: [Modal],
  props: ['id'],
  components: {
    SlideTab,
    VChart,
  },
  data() {
    return {
      report: {},
      no_card: true,
      subcategories: true,
      total: 0,
      totalCard: 0,
      tabChange: 1,
      miniTab: {
        tab: 0,
        indicator: 0,
      },
      tabIncome: 'expense',
      tabColor: '#ff1a1a',
      optionChart: {
        legend: {
          data: ['Despesas'],
          align: 'left'
        },
        tooltip: {
          trigger: "axis",
          show: true,
          backgroundColor : '#fff',
          axisPointer: {
            snap: true,
            type: "cross",
          },
          formatter: function(params){
            var balance = (Math.abs(params[0].data));
            var template = params[0].name + '<h1 class="color-black no-margin">-R$ ' + balance + '</h1> <hr style="margin-top:5px;margin-bottom:5px;">';
            return template;
          }
        },
        xAxis: {
          data: [],
          silent: false,
          axisTick: {
            alignWithLabel: true,
          },
          splitLine: {
            show: false
          }
        },
        yAxis: {
          type : 'value',
          name: '',
          axisLabel: {
            formatter: function (value, index) {
              console.log(index);
              var text = "R$ "+value;
              return text;
            }
          }
        },
        series: [{
            name: 'Despesas',
            type: 'line',
            smooth: false,
            stack: 'transactions',
            areaStyle:{
              normal:{
                color: new graphic.LinearGradient(0, 0, 0, 1, [{ 
                    offset: 0,
                    color:'rgba(255,26,26,0.20)'
                }, {
                    offset: .34,
                    color:'rgba(255,26,26,0.15)'
                },{
                    offset: 1,
                    color:'rgba(255,26,26,0.00)'
                }])
              }
            },
            itemStyle: {
              normal: {
                barBorderRadius: 50,
                color: "#ff1a1a",
              }
            },
            data: [],
            animationDelay: function (idx) {
              return idx * 10;
            }
        }],
        animationEasing: 'elasticOut',
        animationDelayUpdate: function (idx) {
          return idx * 5;
        }
      },
      optionChartIncome: {
        legend: {
          data: ['Receitas'],
          align: 'left'
        },
        tooltip: {
          trigger: "axis",
          show: true,
          backgroundColor : '#fff',
          axisPointer: {
            snap: true,
            type: "cross",
          },
          formatter: function(params){
            var balance = (Math.abs(params[0].data));
            var template = params[0].name + '<h1 class="color-black no-margin">R$ ' + balance + '</h1> <hr style="margin-top:5px;margin-bottom:5px;">';
            return template;
          }
        },
        xAxis: {
          data: [],
          silent: false,
          axisTick: {
            alignWithLabel: true,
          },
          splitLine: {
            show: false
          }
        },
        yAxis: {
          type : 'value',
          name: '',
          axisLabel: {
            formatter: function (value, index) {
              console.log(index);
              var text = "R$ "+value;
              return text;
            }
          }
        },
        series: [{
          name: 'Receitas',
          type: 'line',
          stack: 'transactions',
          barMaxWidth: 10,
          smooth: false,
          areaStyle:{
            normal:{
              color: new graphic.LinearGradient(0, 0, 0, 1, [{ 
                  offset: 0,
                  color:'rgba(55,163,59,0.20)'
              }, {
                  offset: .34,
                  color:'rgba(55,163,59,0.15)'
              },{
                  offset: 1,
                  color:'rgba(55,163,59,0.00)'
              }])
            }
          },
          itemStyle: {
            normal: {
              barBorderRadius: 50,
              color: "#18b272"
            }
          },
          data: [
            2, 2, 10, 20, 4
          ],
          animationDelay: function (idx) {
            return idx * 10 + 100;
          }
        }],
        animationEasing: 'elasticOut',
        animationDelayUpdate: function (idx) {
          return idx * 5;
        }
      },
    };
  },
  mounted() {
    this.$root.$on('show.modal', this.checkBudget);
  },
  beforeDestroy() {
    this.$root.$off('show.modal');
  },
  provide: {
    [THEME_KEY]: "light",
  },
  created() {
  },
  methods: {
    createTransaction() {
      this.hide();
      const transaction = {
        isCard: false,
        type: this.tabIncome,
        category: this.data.id,
        card: null,
        bill_type: {
          type: 'M',
        },
        is_recurrence: false,
        invoice_pay: false,
        invoice: null,
        account: null,
        check_sub: false,
      };
      this.$root.$emit('show.modal', 'create-transaction-modal', transaction);
    },
    removeBudget() {
      this.$root.$emit('call_remove_modal', this.data.id);
      this.hide();
    },
    editBudget() {
      this.hide();
      this.$root.$emit('call_edit_modal', this.data);
    },
    changeTab(tab) {
      this.miniTab.tab = tab;
      switch (tab) {
        case 0:
          this.tabChange = 1;
          break;
        case 1:
          this.tabChange = 2;
          break;
        default:
          break;
      }
    },
    checkBudget() {
      if(this.data) {
        this.optionChart.xAxis.data = [];
        this.optionChart.series[0].data = [];
        this.optionChartIncome.xAxis.data = [];
        this.optionChartIncome.series[0].data = [];
        this.$forceUpdate();
        this.tabIncome = this.data.tabIncome;
        this.total = this.data.total;
        AppService.boxFlow({
          from: this.data.from,
          to: this.data.to,
          get_group: true,
          category: this.data.id,
          type: this.data.tabIncome,
          show_cards: this.no_card,
          subcategories: this.subcategories,
          invoice_date: true,
          trans_card: true,
        }).then(
          (response) => {
            this.optionChart.xAxis.data = response.chart.label;
            this.optionChart.series[0].data = response.chart.expense;
            this.optionChartIncome.xAxis.data = response.chart.label;
            this.optionChartIncome.series[0].data = response.chart.income;
          },
          (error) => {
            console.log(error);
          },
        );
        AppService.getInfo({
          from: this.data.from,
          to: this.data.to,
          type: this.data.tabIncome,
          show_cards: this.no_card,
          category: this.data.id,
          subcategories: this.subcategories,
          invoice_date: true,
          trans_card: true,
        }).then(
          (response) => {
            this.report = response;
            this.totalCard = response.transactions.report.total_card;
            if (this.data.type == 'Income') {
              this.total = response.transactions.report.total_income;
            } else {
              this.total = response.transactions.report.total_expense;
            }
          },
          (error) => {
            console.log(error);
          },
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.btn-add-transaction {
  height: 45px;
  right: 15px;
  z-index: 1;
  top: 80px;
  text-align: center;
  margin-right: 10px;
}

.btn-remove-transaction {
  height: 45px;
  right: 105px;
  z-index: 1;
  top: 80px;
  margin-right: 10px;
}
.btn-info-transaction {
  height: 45px;
  right: 195px;
  z-index: 1;
  top: 80px;
  margin-right: 10px;
}

textarea:not(:last-child) {
  border-bottom: none;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}
.textarea-information {
  border: 1px solid #dfe7f3;
  border-top: none;
  border-bottom-left-radius: 0.25rem;
  border-bottom-right-radius: 0.25rem;
  padding: 0.2rem 0.5rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}
textarea:focus + .textarea-information {
  border: 1px solid #635ebe;
  border-top: none;
}

.actions-var {
  position: relative !important;
}
.emoji-color {
  color: #ccd2dc !important;
}
.actions-var-cancel {
  text-align: center;
  color: #4d5a68;
  border-top: 1px solid rgba(234, 243, 253, 0.7);
  padding-top: 5px;
  padding-bottom: 0px;
}
.actions-icon {
  font-size: 14px !important;
}
.actions-var a {
  cursor: pointer;
}
.fr-wrapper > *:first-child {
  display: none;
}
.fr-wrapper .fr-element {
  display: block;
}
.second-toolbar a {
  visibility: hidden;
}
#cke_673 {
  display: none !important;
}
.cke_toolbar_last {
  display: none;
}
.modal-body {
  padding: 25px;
}
.popup-icons {
  height: 50px;
}
.popup-icons .stpe-icons-center {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 64px;
  margin: auto;
}
.popup-icons .stpe-icons-center a.stpe-logo-banks {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
  background: #fff;
  overflow: hidden;
  cursor: default;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.popup-icons .stpe-icons-center a.stpe-logo-banks img {
  display: block;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 60px;
  height: 60px;
  margin: auto;
  -webkit-border-radius: 50%;
  -moz-border-radius: 50%;
  border-radius: 50%;
}
.stpe-component_dropdown .stpe-dropdown-area {
  display: none;
  position: absolute;
  top: 15px;
  left: 120px;
  width: 190px;
  margin-top: 4px;
  margin-left: -95px;
  padding-top: 12px;
  z-index: 2;
}
.drop-down-select-month:hover .stpe-dropdown-area{
  display: block;
}
.stpe-component_dropdown .stpe-dropdown-area .stpe-dropdown-box {
  background: #fff;
  -webkit-box-shadow: 0 2px 20px rgba(0,0,0,.15);
  -moz-box-shadow: 0 2px 20px rgba(0,0,0,.15);
  box-shadow: 0 2px 20px rgba(0,0,0,.15);
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
}
.stpe-component_dropdown .stpe-dropdown-area .stpe-dropdown-box .icon-caret-up {
  position: absolute;
  top: -5px;
  left: 120px;
  margin-left: -10px;
  color: #fff;
  font-size: 21px;
}
.stpe-dropdown-box ul {
  list-style: none;
  margin: 0px;
  padding: 0px;
}
.stpe-component_dropdown .stpe-dropdown-area .stpe-dropdown-box ul li {
  float: none;
}
.stpe-component_dropdown .stpe-dropdown-area .stpe-dropdown-box.stpe-dropdown-text-center a {
  display: block;
  text-align: left;
}
.stpe-component_dropdown .stpe-dropdown-area .stpe-dropdown-box ul li a {
  display: block;
  padding: 7px 18px;
  text-align: left;
  text-transform: none;
  color: #777;
  font-size: 14px;
  letter-spacing: 0;
  -webkit-transition: all .15s ease-out 0s;
  -moz-transition: all .15s ease-out 0s;
  -o-transition: all .15s ease-out 0s;
  transition: all .15s ease-out 0s;
  overflow: hidden;
  text-overflow: ellipsis;
  display: inline-block;
  max-width: 99.9%;
  white-space: nowrap;
}
.stpe-dropdown-li {
  cursor: pointer;
}
.stpe-context_transactions-dialog form .input-row .drop-down-select-month a {
  font-size: 12px;
  line-height: 12px;
}
.stpe-dropdown-action {
  color: #18b272;
}
.stpe-invoice-active {
  background: #44e8a4;
}
.stpe-invoice-active a{
  color: #fff !important;
}
.mx-datepicker {
  width: 100% !important;
}
.alert-error {
  list-style: none;
  margin: 0px;
  padding: 15px;
}
.alert-error .alert {
  padding: 0;
  padding-left: 0px;
  padding-left: 8px;
  font-size: 13px;
  margin-bottom: 10px;
}
.chart-line {
  height: 350px;
  margin-top: 30px;
}
</style>
